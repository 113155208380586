/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable no-console */
import { useActor } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import { useAsyncEffect } from 'use-async-effect';
import { assign, createMachine, interpret, State } from 'xstate';

import {
  fetchDocumentStatus,
  triggerProcessing,
  uploadDocumentToService,
} from '../api/document/upload';
import { getSession, SessionMetadata } from '../api/session';
import { wait } from '../util/helpers';
import { logToApm } from '../util/log-error';

type UploadReceiveData = {
  previewText: string;
  hubbleId: string;
  documentId: string;
};

export type TContext = {
  document: File | null;
  preview: string | null;
  documentId: string | null;
  sessionId: string | null;
  downloadUrl: string | null;
  hasMoreText: boolean;
};

export type TEvent =
  | { type: 'UPLOAD_INITIATE'; document: File }
  | { type: 'UPLOAD_START'; sessionId: string }
  | { type: 'UPLOAD_RECEIVE_DATA'; data: UploadReceiveData }
  | { type: 'UPLOAD_CANCEL' }
  | { type: 'UPLOAD_CLEAR' }
  | { type: 'UPLOADED' }
  | { type: 'PROCESSING_DOCUMENT_INITIATE' }
  | { type: 'PROCESSING_DOCUMENT' }
  | { type: 'PROCESSING_DOCUMENT_DONE'; url: string }
  | { type: 'PROCESSING_DOCUMENT_FAILED' }
  | { type: 'PAYMENT_INITIATE' }
  | { type: 'PAYMENT_PROCESS' }
  | { type: 'PAYMENT_CANCEL' }
  | { type: 'PAYMENT_RESOLVED' }
  | { type: 'PAYMENT_REJECTED' };

export type TState =
  | { value: 'uninitialized'; context: TContext }
  | { value: 'idle'; context: TContext }
  | { value: 'preUpload'; context: TContext }
  | { value: 'uploading'; context: TContext }
  | { value: 'uploaded'; context: TContext }
  | { value: 'processing'; context: TContext }
  | { value: 'documentProcessed'; context: TContext }
  | { value: 'documentProcessingFailed'; context: TContext };

const defaultContext = {
  document: null,
  preview: null,
  documentId: null,
  sessionId: null,
  downloadUrl: null,
  hasMoreText: false,
};

const documentMachine = createMachine<TContext, TEvent, TState>(
  {
    id: 'document',
    strict: true,
    initial: 'uninitialized',
    context: defaultContext,
    on: {
      UPLOAD_CLEAR: { target: '.idle', actions: 'clearContext' },
      UPLOAD_INITIATE: { target: '.preUpload', actions: 'clearAndAssignDocumentToContext' },
    },
    states: {
      uninitialized: {
        on: {},
      },
      idle: {
        on: {},
      },
      preUpload: {
        on: {
          UPLOAD_START: { target: 'uploading' },
        },
      },
      uploading: {
        invoke: {
          src: 'uploadDocument',
          onDone: {
            target: 'uploaded',
            actions: 'assignPreviewToContext',
          },
          onError: 'idle',
        },
        on: {
          UPLOAD_CANCEL: { target: 'idle' },
        },
      },
      uploaded: {
        on: {
          PROCESSING_DOCUMENT_INITIATE: { target: 'processing' },
        },
      },
      processing: {
        invoke: {
          src: 'triggerProcessing',
          onDone: 'processingStatus',
          onError: 'documentProcessingFailed',
        },
      },
      processingStatus: {
        invoke: {
          src: 'getProcessingStatus',
        },
        on: {
          PROCESSING_DOCUMENT: 'processingStatus',
          PROCESSING_DOCUMENT_DONE: {
            target: 'documentProcessed',
            actions: 'assignDownloadUrlToContext',
          },
          PROCESSING_DOCUMENT_FAILED: 'documentProcessingFailed',
        },
      },
      documentProcessed: {
        on: {
          PAYMENT_INITIATE: 'showPayment',
        },
      },
      documentProcessingFailed: {
        type: 'final',
      },
      showPayment: {
        on: {
          PAYMENT_PROCESS: 'processingPayment',
          PAYMENT_CANCEL: 'documentProcessed',
        },
      },
      processingPayment: {
        on: {
          PAYMENT_RESOLVED: 'documentPaid',
          PAYMENT_REJECTED: 'showPayment', // and an error notification
        },
      },
      documentPaid: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      clearContext: assign((context, event: any) => {
        return defaultContext;
      }),
      clearAndAssignDocumentToContext: assign((context, event: any) => {
        return {
          ...defaultContext,
          document: event.document,
        };
      }),
      assignPreviewToContext: assign((context, event: any) => {
        return {
          ...context,
          preview: event.data.previewText,
          documentId: event.data.documentId,
          sessionId: event.data.sessionId,
          hasMoreText: event.data.hasMoreText,
        };
      }),
      assignDownloadUrlToContext: assign((context, event) => {
        if (event.type !== 'PROCESSING_DOCUMENT_DONE') {
          return context;
        }
        return {
          ...context,
          downloadUrl: event.url,
        };
      }),
    },
    services: {
      uploadDocument: async (context, event) => {
        try {
          if (event.type !== 'UPLOAD_START') {
            return logToApm('event type is not UPLOAD_START');
          }
          if (context.document === null) {
            return logToApm('context document does not exist');
          }
          return await uploadDocumentToService({
            document: context.document,
            sessionId: event.sessionId,
          });
        } catch (error) {
          logToApm(error);
          return error;
        }
      },
      triggerProcessing: (context, event) => async () => {
        return await triggerProcessing({
          documentId: context.documentId as string,
          sessionId: context.sessionId as string,
        });
      },
      getProcessingStatus: ({ sessionId, documentId }, event) => async (callback) => {
        try {
          await wait(1000);
          if (!sessionId || !documentId) {
            throw new Error(`missing sessionId[${sessionId}] or documentId[${sessionId}]`);
          }
          const res = await fetchDocumentStatus({
            sessionId,
            documentId,
          });
          if (res.data.status === 'SUBMITTED') {
            return callback('PROCESSING_DOCUMENT');
          }
          return callback({ type: 'PROCESSING_DOCUMENT_DONE', url: res.data.url });
        } catch {
          //FIXME: log error to APM;
          return callback('PROCESSING_DOCUMENT_FAILED');
        }
      },
    },
    guards: {},
  },
);

const documentService = interpret(documentMachine, {
  devTools: true,
});

export const useDocumentState = () => {
  const { replace } = useHistory();
  // TODO: probably move this effect somewhere else, maybe to the document context
  useAsyncEffect(async () => {
    const sessionId = window.location.pathname.split('/')[1];

    if (!sessionId) {
      // not restoring any session
      documentService.start('idle');
      return;
    }
    let sessionMetadata: SessionMetadata['data'];
    try {
      const response = await getSession(sessionId);
      sessionMetadata = response.data;
    } catch (error) {
      // could not get session metadata, so we're navigating as if we didn't have a session ID
      replace('/');
      logToApm(error);
      documentService.start('idle');
      return;
    }

    const restoredContext: TContext = {
      document: null,
      preview: sessionMetadata.documentInfo.previewText,
      documentId: sessionMetadata.documentInfo.documentId,
      sessionId,
      downloadUrl: sessionMetadata.documentInfo.url || null,
      hasMoreText: sessionMetadata.documentInfo.hasMoreText,
    };
    let restoredStateValue: string | { [key: string]: any };
    // TODO: convert this to an object Map when we need to handle all the statuses
    switch (sessionMetadata.documentInfo.status) {
      case 'UPLOADED': {
        restoredStateValue = 'uploaded';
        break;
      }
      case 'SUBMITTED': {
        restoredStateValue = {
          uploaded: {
            documentProcessing: 'processing',
          },
        };
        break;
      }
      case 'SUCCESS': {
        restoredStateValue = {
          uploaded: {
            documentProcessing: 'done',
          },
        };
        break;
      }
      default: {
        throw new Error(
          `unknown state to restore from sessionMetadata: ${sessionMetadata.documentInfo.status}`,
        );
      }
    }
    const resolvedState = documentMachine.resolveState(
      State.from<TContext, TEvent>(restoredStateValue, restoredContext),
    );
    documentService.start(resolvedState);
  }, []);

  return useActor(documentService);
};
