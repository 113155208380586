import { PropsWithChildren, useMemo, useState } from 'react';

import Modal from '../components/modals';

export const useModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const ModalComponent = useMemo(
    () => ({ children }: PropsWithChildren<any>) =>
      isModalOpen ? <Modal onClose={() => setModalOpen(false)}>{children}</Modal> : null,
    [setModalOpen, isModalOpen],
  );

  return {
    // FIXME: update props type when using this hook
    Modal: ModalComponent,
    openModal: () => setModalOpen(true),
    closeModal: () => setModalOpen(false),
    isModalOpen,
  };
};
