import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import {
  checkSubmission,
  CheckSubmissionResult,
  intentPayment,
  // IntentPaymentResult,
} from '../../../api/payment/index';
import { useModal } from '../../../hooks/use-modal';
import { COUNTRIES } from '../../../util/countries';
import { isDevelopment, wait } from '../../../util/helpers';
import { logToApm } from '../../../util/log-error';
import Button from '../../cta-button';
import Checkbox from '../../fields/checkbox';
import CoreHelperText from '../../fields/core-helper-text';
import Input from '../../fields/input';
import Select from '../../fields/select';
import TocModal from '../toc-modal';

type CheckForCriticalErrorArgs = Inputs & {
  cardElement: StripeCardElement | null;
  documentId: Props['documentId'];
};

type Inputs = {
  name: string;
  zipCode: string;
  city: string;
  country: string;
  terms: boolean;
  email: string;
  creditCard: string;
};

type PaymentData = {
  // eslint-disable-next-line camelcase
  payment_method: {
    card: StripeCardElement;
    // eslint-disable-next-line camelcase
    billing_details: {
      name: string;
      email: string;
      address: {
        // eslint-disable-next-line camelcase
        postal_code: string;
        city: string;
        country: string;
      };
    };
  };
};

type Props = {
  documentId: string | null;
  sessionId: string | null;
  onSubmit: (data: CheckSubmissionResult) => void;
  isProcessing: boolean;
  // paymentKeys: IntentPaymentResult['intent'];
};

const getCountries = () => Object.entries(COUNTRIES).map(([value, label]) => ({ value, label }));

function checkForCriticalError(args: CheckForCriticalErrorArgs) {
  let errorMessage: string | null = null;
  if (!args.cardElement) {
    errorMessage = '`cardElement` is missing';
  }

  if (args.terms === false) {
    errorMessage = '`terms` is missing';
  }

  if (args.documentId === null) {
    errorMessage = '`documentId` is missing';
  }

  return errorMessage;
}

const PaymentForm: React.FC<Props> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { openModal, Modal } = useModal();
  const [step, setStep] = useState<'personal' | 'payment'>('personal');
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm<Inputs>(
    isDevelopment
      ? {
          defaultValues: {
            city: 'John town',
            name: 'John doe',
            zipCode: '0000',
            country: 'Denmark',
            terms: true,
            email: 'john.doe@cactusglobal.com',
          },
        }
      : {},
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (step === 'personal') {
      return setStep('payment');
    }
    try {
      if (paymentError) {
        setPaymentError(null);
      }
      setLoading(true);
      if (!stripe || !elements) {
        throw new Error('no stripe or elements');
      }

      const cardElement = elements.getElement('card');
      const criticalError = checkForCriticalError({
        cardElement,
        ...data,
        documentId: props.documentId,
      });
      if (criticalError) {
        logToApm(criticalError);
        setLoading(false);
        return;
      }

      const paymentKeys = await intentPayment({
        documentId: props.documentId as string,
        sessionId: props.sessionId as string,
      });

      const paymentData: PaymentData = {
        // eslint-disable-next-line camelcase
        payment_method: {
          card: cardElement as StripeCardElement,
          // eslint-disable-next-line camelcase
          billing_details: {
            name: data.name,
            email: data.email,
            address: {
              // eslint-disable-next-line camelcase
              postal_code: data.zipCode,
              city: data.city,
              country: data.country,
            },
          },
        },
      };

      const confirm = await stripe.confirmCardPayment(
        paymentKeys.intent.paymentIntentSecret,
        paymentData,
      );

      if (confirm.paymentIntent?.status === 'succeeded') {
        let SubmissionCheck: CheckSubmissionResult | null = null;
        let RETRY = 0;
        while (SubmissionCheck?.status !== 'PAYMENT_COMPLETED' && RETRY < 5) {
          const check = await checkSubmission(props.documentId as string);
          wait(2000);
          if (check === null) {
            throw new Error('An unknown error');
          }
          SubmissionCheck = check;
          RETRY++;
        }

        setLoading(false);
        props.onSubmit(SubmissionCheck as CheckSubmissionResult);
        return;
      }

      throw new Error('Payment did not succeed');
    } catch (error) {
      logToApm(error.message);
      setLoading(false);
      setPaymentError(error?.json?.errorReason || error.message);
    }
  };

  const onCardChange = useCallback(
    (ev: StripeCardElementChangeEvent) => {
      if (ev.complete || !ev.error?.message) {
        clearErrors('creditCard');
        return;
      }
      setError('creditCard', { type: 'manual', message: ev.error?.message });
    },
    [setError, clearErrors],
  );

  // default view on form
  let content = (
    <>
      <NameInput
        {...register('name', { required: 'Please enter your name' })}
        errors={errors}
        label="Your name"
      />
      <ZipCodeInput
        {...register('zipCode', { required: 'Please enter your zip' })}
        errors={errors}
        label="Zip code"
      />
      <CityInput
        {...register('city', { required: 'Please enter your city' })}
        errors={errors}
        label="City"
      />
      <CountrySelect
        {...register('country', { required: 'Please enter your country' })}
        errors={errors}
        options={getCountries()}
        label="Country"
      />
      <TermsCheckbox
        {...register('terms', {
          required: 'Please read and accept the terms of condition',
        })}
        errors={errors}
        label={
          <>
            I accept the{' '}
            <LinkButton underline type="button" onClick={openModal}>
              terms and conditions
            </LinkButton>
          </>
        }
      />
    </>
  );

  // view after personal step
  if (step === 'payment') {
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;
    content = (
      <>
        <PersonalInfo>
          <span>{getValues('name')}</span>
          <span>
            {getValues('zipCode')} {getValues('city')}
          </span>
          <span>{COUNTRIES[getValues('country')]}</span>
        </PersonalInfo>
        <LinkButton onClick={() => setStep('personal')}>Edit</LinkButton>
        <Separator />
        <EmailInput
          {...register('email', {
            required: 'Please enter your email',
            pattern: {
              value: emailRegex,
              message: 'Invalid email address',
            },
          })}
          errors={errors}
          label="Your email address"
        />
        <CCInput
          label="Credit card"
          name="creditCard"
          errors={errors}
          Component={
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '1.5rem',
                  },

                  invalid: {
                    color: '#cc0044',
                  },
                },
              }}
              onChange={onCardChange}
              onFocus={() => setPaymentError(null)}
            />
          }
        />
      </>
    );
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {content}
        <ErrorMessage>{paymentError}</ErrorMessage>
        <CtaButton type="submit" loading={loading}>
          {step === 'personal' ? 'Continue' : 'Pay and download now'}
        </CtaButton>
      </Form>
      <Modal>
        <TocModal />
      </Modal>
    </>
  );
};

export default PaymentForm;

const Form = styled.form`
  display: grid;
  height: 100%;
  width: 100%;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, auto);
  align-items: center;
`;

const NameInput = styled(Input)`
  grid-column: 1/-1;
`;
const ZipCodeInput = styled(Input)`
  grid-column: 1/3;
`;
const CityInput = styled(Input)`
  grid-column: 3/-1;
`;
const CountrySelect = styled(Select)`
  grid-column: 1/-1;
`;
const TermsCheckbox = styled(Checkbox)`
  grid-column: 1/-1;
`;
const EmailInput = styled(Input)`
  grid-column: 1/-1;
`;

const CCInput = styled(Input)`
  grid-column: 1/-1;
`;

const LinkButton = styled.button<{ underline?: boolean }>`
  border: none;
  background: none;
  font-size: inherit;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  padding: 0;
  text-align: end;
`;

const ErrorMessage = styled(CoreHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
  grid-column: 1/-1;
  grid-row: 5/6;
`;

const CtaButton = styled(Button)`
  grid-column: 1/-1;
`;

const PersonalInfo = styled.div`
  grid-column: 1/5;
  line-height: 2.4rem;
  > span {
    display: block;
  }
`;

const Separator = styled.div`
  height: 0.2rem;
  background: #d4dae4;
  grid-column: 1/-1;
`;
