import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  checkSubmission,
  CheckSubmissionResult,
  // intentPayment,
  // IntentPaymentResult,
} from '../../../api/payment/index';
import { useDocument } from '../../../context/document-context';
import Icon from '../../icon';
import MSWordIcon from '../../icons/msword-icon';
import ModalTitle from '../modal-title';
import PaymentForm from './payment-form';

function startAutoDownload(url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download');
  document.body.append(link);
  link.click();
  link.remove();
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET as string);

const PaymentModal: React.FC = () => {
  const { state } = useDocument();
  const [payment, setPayment] = useState<CheckSubmissionResult | null>(null);
  // const paymentKeys = useRef<IntentPaymentResult['intent']>();

  useEffect(() => {
    (async () => {
      if (state.context.documentId) {
        const res = await checkSubmission(state.context.documentId);
        setPayment(res);
      }
    })();
  }, [state.context.documentId]);

  // useEffect(() => {
  //   (async () => {
  //     if (state.context.documentId && state.context.sessionId) {
  //       const res = await intentPayment({
  //         documentId: state.context.documentId as string,
  //         sessionId: state.context.sessionId as string,
  //       });
  //       paymentKeys.current = res.intent;
  //     }
  //   })();
  // }, [state.context.documentId, state.context.sessionId]);

  const AUTO_DOWNLOAD_INITIALIZED = useRef(false);

  useEffect(() => {
    if (
      state.context.downloadUrl &&
      !AUTO_DOWNLOAD_INITIALIZED.current &&
      (payment?.status === 'PAYMENT_COMPLETED' || payment?.status === 'EMAIL_SENT') &&
      !payment.downloadExpired
    ) {
      startAutoDownload(state.context.downloadUrl);
      AUTO_DOWNLOAD_INITIALIZED.current = true;
    }
  }, [state.context.downloadUrl, payment?.status, payment?.downloadExpired]);

  const onSubmit = (data: CheckSubmissionResult) => {
    setPayment(data);
  };

  // default, when payment modal open and the payment is not completed
  let content = (
    <PaymentForm
      // paymentKeys={paymentKeys.current}
      documentId={state.context.documentId}
      sessionId={state.context.sessionId}
      isProcessing={!state.matches('documentProcessed')}
      onSubmit={onSubmit}
    />
  );

  // if the payment is COMPLETED this will show instead of paymentForm
  if (payment?.status === 'PAYMENT_COMPLETED' || payment?.status === 'EMAIL_SENT') {
    content = (
      <>
        <Headline>Payment confirmed</Headline>
        <Check>
          <Icon icon={faCheck} size="3x" />
        </Check>
        {payment.downloadExpired ? (
          <Text>The link is expired</Text>
        ) : (
          <Text>
            Your download should start automatically. <br />{' '}
            {state.context.downloadUrl && (
              <>
                If not, please{' '}
                <a href={state.context.downloadUrl} download>
                  click here to download.
                </a>
              </>
            )}
          </Text>
        )}
        <Text fontWeight="bold">
          Your file is available for {payment.downloadAvailableDays}{' '}
          {payment.downloadAvailableDays > 1 ? 'days' : 'day'}.
        </Text>
        <Text>We have emailed you the payment receipt as well as a link to your document.</Text>
      </>
    );
  }

  return (
    <>
      <ModalTitle>Download your Paperpal Edited Word file</ModalTitle>
      <Wrapper>
        <Content bg="primary">
          <MSWord>
            <MSWordIcon height={82} />
          </MSWord>
          <Text>
            Your Paperpal Edited Word file includes all our edits as <em>tracked changes</em> in
            your originally uploaded document.
          </Text>
          <Text fontWeight="bold">All your formatting will be intact.</Text>
        </Content>
        <Content bg="secondary">{content}</Content>
      </Wrapper>
    </>
  );
};

const PaymentModalWrapper: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentModal />
    </Elements>
  );
};

export default PaymentModalWrapper;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
`;
const Content = styled.div<{ bg: 'primary' | 'secondary' }>`
  display: grid;
  flex: 1;
  row-gap: 2rem;
  place-items: center;
  padding: 2rem;
  border-radius: 1rem;
  background-color: ${({ theme, bg }) => theme.palette.background[bg]};
`;

const MSWord = styled.div`
  display: grid;
  place-items: center;
  background: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 50%;
  width: 11.25rem;
  height: 11.25rem;
`;

const Check = styled(MSWord)`
  width: 12rem;
  height: 12rem;
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.primary.light};
`;

const Headline = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Text = styled.p<{ fontWeight?: string | number }>`
  text-align: center;
  line-height: 2.4rem;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;
